import { ReactNode } from 'react';
import { Heading } from '@requity-homes/component-library';
export interface MainContentProps {
  children: ReactNode;
  className?: string;
  title?: string;
}

export function MainContent({ children, className }: MainContentProps) {
  return (
    <main
      className={`flex flex-col flex-1 pb-6 md:p-0 md:flex-col w-full md:flex md:max-h-screen md:px-0 ${
        className || ''
      }`}
    >
      {children}
    </main>
  );
}
