import { useEffect, useState } from 'react';
import NextLink from 'next/link';
import { getCookie, setCookie } from 'cookies-next';

export const StickyFooter = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const wrapDismissed = getCookie('listing-cookie-wrap-dismissed');
    if (wrapDismissed) {
      setIsVisible(false);
    }
  }, []);

  const handleDismiss = () => {
    setCookie('listing-cookie-wrap-dismissed', 'true', {
      expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), // 30 days
    });
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-coral-light p-4 flex justify-between items-center shadow-lg">
      <span>
        By using our website, you agree to our{' '}
        <NextLink passHref href="https://www.requityhomes.com/legal">
          <a className="underline text-sm" target="_blank">
            Terms of Service
          </a>
        </NextLink>{' '}
        and{' '}
        <NextLink passHref href="https://www.requityhomes.com/privacy-policy">
          <a className="underline text-sm" target="_blank">
            Privacy Policy
          </a>
        </NextLink>
        .
      </span>
      <button
        className="bg-coral-medium text-white px-4 py-2 rounded"
        onClick={handleDismiss}
      >
        Dismiss
      </button>
    </div>
  );
};
