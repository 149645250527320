import { ParsedUrlQuery } from 'querystring';
import { SearchListingsFilterValues } from '../graphql/generated';
import { filterOutNotSearchableValues } from './filter-out-not-searchable-values';

export function getFilterValuesFromQuery(query: ParsedUrlQuery, city?: string) {
  if (!city) {
    city = query.city as string;
  }
  let filterValues = {
    hasImages: true,
    sortBy: 'recommended',
    page: 1,
    city,
    ...query,
  } as SearchListingsFilterValues;

  filterValues = filterOutNotSearchableValues(filterValues);

  return filterValues;
}
