import { useState, useEffect } from 'react';

export const useIsMobileView = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () =>
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);

    checkIsMobile(); // Initial check

    window.addEventListener('resize', checkIsMobile);

    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  return isMobile;
};
