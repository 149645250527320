import * as React from 'react';
import axios from 'axios';
import { twMerge } from 'tailwind-merge';
import { Icon } from '@requity-homes/component-library';
import { removeEmptyValues } from '@requity-homes/utils';

const mapBoxToken = process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN;
const mapBoxSessionToken = process.env.NEXT_PUBLIC_MAPBOX_SESSION_TOKEN;

export interface SearchBoxProps {
  className?: string;
  inputClassName?: string;
  handleAreaChange: (center: { lat: number; lng: number }) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  centerLocation?: { lat: number; lng: number };
}

interface Suggestion {
  full_address: string;
  place_formatted: string;
  name: string;
  context: {
    address: {
      name: string;
    };
    place: {
      name: string;
    };
    region: {
      name: string;
    };
  };
}

interface MapBoxSearchParams {
  access_token: string;
  session_token: string;
  q: string;
  country: string;
  types: string;
  limit: number;
  language: string;
  proximity?: string;
}

export function SearchBox({
  className,
  inputClassName,
  handleAreaChange,
  onBlur,
  onFocus,
  centerLocation,
}: SearchBoxProps) {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [suggestions, setSuggestions] = React.useState<string[]>([]);
  const [isFocused, setIsFocused] = React.useState(false);

  async function searchArea(area: string) {
    try {
      const params: MapBoxSearchParams = {
        q: area,
        access_token: mapBoxToken,
        session_token: mapBoxSessionToken,
        language: 'en',
        country: 'CAN',
        limit: 10,
        types:
          'region,district,postcode,locality,place,neighborhood,address,street',
      };

      if (centerLocation) {
        params.proximity = `${centerLocation.lng},${centerLocation.lat}`;
      }

      const config = {
        method: 'get',
        url: `https://api.mapbox.com/search/searchbox/v1/suggest`,
        params,
      };

      const response = await axios(config);

      return response.data;
    } catch (error) {
      console.error(`Error searching for ${area}`, error);
    }
  }

  const handleSearchArea = async (area) => {
    const res = await searchArea(area);
    console.log('res', JSON.stringify(res, null, 2));
    setSuggestions(
      res.suggestions.map((suggestion: Suggestion) => {
        if (suggestion.full_address) {
          return [
            suggestion.context?.address?.name,
            suggestion.context?.place?.name,
            suggestion.context?.region?.name,
          ]
            .filter(Boolean)
            .join(', ');
        } else {
          return [
            suggestion.name,
            suggestion.context?.place?.name,
            suggestion.context?.region?.name,
          ]
            .filter(Boolean)
            .join(', ');
        }
      }),
    );
  };

  async function getCoordinates(area) {
    try {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${area}.json?access_token=${mapBoxToken}`,
      );
      return response.data;
    } catch (error) {
      console.error('error', error);
      throw new Error('Error searching');
    }
  }

  const getSuggestionCoordinates = async (area) => {
    const res = await getCoordinates(area);

    handleAreaChange({
      lat: res.features[0].center[1],
      lng: res.features[0].center[0],
    });
  };

  function onSuggestionClick(suggestion: string) {
    return async () => {
      setSearchTerm(suggestion);
      setSuggestions([]);
      await getSuggestionCoordinates(suggestion);
      setIsFocused(false);
    };
  }

  return (
    <div className={twMerge('flex-1 flex flex-col z-20', className)}>
      {isFocused && (
        <div
          className="fixed inset-0 z-10 backdrop-brightness-50 h-screen w-screen shadow-md-dark"
          onClick={() => setIsFocused(false)}
        />
      )}
      <div
        className={twMerge(isFocused ? 'z-30' : '', 'relative', inputClassName)}
      >
        <Icon
          glyph="search"
          className="absolute left-6 md:left-4 top-1/2 transform -translate-y-1/2"
        />
        <input
          type="text"
          name={searchTerm}
          value={searchTerm}
          placeholder="Search by city, address"
          className="placeholder:text-coral-med placeholder:text-center placeholder:font-bold border border-gray-300 rounded-full pl-12 md:px-8 py-2 w-full shadow-md-dark -mt-1 md:mt:0"
          onBlur={() => {
            onBlur();
          }}
          onFocus={() => {
            onFocus();
            setIsFocused(true);
          }}
          onChange={async (e) => {
            if (e.target.value) {
              setSearchTerm(e.target.value);
              handleSearchArea(e.target.value);
            } else {
              setSearchTerm('');
              setSuggestions([]);
            }
          }}
        />
      </div>
      {isFocused && suggestions.length > 0 && (
        <div className="bg-white flex flex-col gap-2 p-4 md:w-3/4 w-full z-30">
          {suggestions.map((suggestion, idx) => (
            <span
              key={idx}
              className="text-s cursor-pointer hover:text-coral-med inline-block border-b-coral-med border-solid border-0 border-b min-h-8"
              onClick={onSuggestionClick(suggestion)}
            >
              {suggestion}
            </span>
          ))}
        </div>
      )}
    </div>
  );
}
