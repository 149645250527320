import { getCustomerPortalBaseUrl } from './get-customer-portal-base-url';
import {
  cookiesListingPortalRedirectPath,
  setCrossDomainCookie,
} from '@requity-homes/utils';

export const redirectToCustomerPortal = () => {
  const url = new URL(window.location.href);
  const pathWithQuery = url.pathname + url.search;

  setCrossDomainCookie(cookiesListingPortalRedirectPath, pathWithQuery);

  const customerPortalUrl = `${getCustomerPortalBaseUrl(
    process.env.NEXT_PUBLIC_STAGE,
  )}/auth/sign-up`;

  window.open(customerPortalUrl, '_blank');
};
