import NextLink from 'next/link';
import Head from 'next/head';
import { Footer, Header, MainContent, PageWrapper } from '..';
import { Heading, Button, Text } from '@requity-homes/component-library';

export const Custom404 = () => {
  return (
    <PageWrapper>
      <Head>Page not found</Head>
      <MainContent>
        <Header />
        <div className="flex flex-col justify-between md:h-screen background-image pt-16">
          <div className="flex flex-col gap-8 md:gap-0 md:flex-row md:justify-between mt-8 md:mt-24 pb-12">
            <div className="flex-1">
              <div className="px-24">
                <img
                  src="https://assets-global.website-files.com/61e19be21795b3fa062d021f/65f31bdae32a00bd54ee04b9_404%20People.svg"
                  alt=""
                />
              </div>
            </div>
            <div className="flex text-center md:text-left flex-col gap-4 flex-1">
              <Heading level="h2" className="px-8 md:pl-0 md:pr-12">
                Sorry, the home listing you are looking for is no longer
                available.
              </Heading>
              <Text useCase="paragraph">
                Explore our home listing page to discover more options.
              </Text>
              <div className="flex gap-4 justify-center md:justify-start">
                <NextLink passHref href="/">
                  <a>
                    <Button className="mt-8 md:px-12 text-coral-medium active:bg-white bg-white outline-none border-transparent hover:bg-coral-medium hover:text-white">
                      Browse Homes
                    </Button>
                  </a>
                </NextLink>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </MainContent>
    </PageWrapper>
  );
};
