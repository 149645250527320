import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import copy from 'copy-text-to-clipboard';
import {
  Button,
  Heading,
  Icon,
  Popup,
  Text,
} from '@requity-homes/component-library';
import { Listing } from '../../graphql/generated';
import { generateListingUrl } from '@requity-homes/utils';

interface ShareListingPopupProps {
  listing: Listing;
  handleModalClose: () => void;
}

export const ShareListingPopup = ({
  listing,
  handleModalClose,
}: ShareListingPopupProps) => {
  const stage = process.env.NEXT_PUBLIC_STAGE;
  const listingUrl = generateListingUrl(listing, stage);

  const handleCopy = () => {
    copy(listingUrl);

    toast.success('Success! Your link has been copied to the clipboard', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  };

  return (
    <Popup showCloseIcon handleClose={handleModalClose}>
      <div className="px-4">
        <Heading
          level="h4"
          element="h4"
          fontFace="sans"
          className="text-center -mt-6 mb-4"
        >
          Share a link
        </Heading>
        <div>
          <div className="flex justify-between">
            <div className="flex flex-col items-center gap-1">
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  listingUrl,
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                className="no-underline"
              >
                <Icon glyph="facebook" height={38} width={38} />
              </a>
              <Text useCase="legal">Facebook</Text>
            </div>
            <div className="flex flex-col items-center gap-1">
              <a
                href={`mailto:info@requityhomes.com?body=${encodeURIComponent(
                  listingUrl,
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                className="no-underline"
              >
                <Icon glyph="mail" height={38} width={38} />
              </a>
              <Text useCase="legal">Email</Text>
            </div>
            <div className="flex flex-col items-center gap-1">
              <a
                href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                  listingUrl,
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                className="no-underline"
              >
                <Icon glyph="whatsapp" height={38} width={38} />
              </a>
              <Text useCase="legal">WhatsApp</Text>
            </div>
            <div className="flex flex-col items-center gap-1">
              <a
                href={`https://www.reddit.com/submit?url=${encodeURIComponent(
                  listingUrl,
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                className="no-underline"
              >
                <Icon glyph="reddit" height={38} width={38} />
              </a>
              <Text useCase="legal">Reddit</Text>
            </div>
          </div>

          <div className="flex flex-col gap-4 bg-coral-thin-05 rounded-xl mt-8 ">
            <Text className="px-4 py-2 bg-pure-white border border-grey-4 rounded-xl overflow-y-auto break-all text-sm">
              {listingUrl}
            </Text>
            <Button
              onClick={handleCopy}
              type="button"
              color="coral"
              className="w-32 border-none bg-coral-medium shadow-md-dark text-center sm:px-0 sm:py-2  text-white"
            >
              Copy
            </Button>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={700}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </Popup>
  );
};
