import { Province } from '@requity-homes/underwriting';
import { ListedCity } from '@requity-homes/utils';

export const cityToProvinceMap: Record<ListedCity, Province> = {
  [ListedCity.Edmonton]: Province.Alberta,
  [ListedCity.SpruceGrove]: Province.Alberta,
  [ListedCity.StAlbert]: Province.Alberta,
  [ListedCity.StonyPlain]: Province.Alberta,
  [ListedCity.SherwoodPark]: Province.Alberta,
  [ListedCity.RedDeer]: Province.Alberta,
  [ListedCity.Lethbridge]: Province.Alberta,
  [ListedCity.GrandePrairie]: Province.Alberta,
  [ListedCity.Airdrie]: Province.Alberta,
  [ListedCity.Beaumont]: Province.Alberta,
  [ListedCity.Leduc]: Province.Alberta,
  [ListedCity.NorthBay]: Province.Ontario,
  [ListedCity.SaultSteMarie]: Province.Ontario,
  [ListedCity.Sudbury]: Province.Ontario,
  [ListedCity.ThunderBay]: Province.Ontario,
  [ListedCity.Saskatoon]: Province.Saskatchewan,
  [ListedCity.Martensville]: Province.Saskatchewan,
  [ListedCity.Warman]: Province.Saskatchewan,
  [ListedCity.Regina]: Province.Saskatchewan,
  [ListedCity.PrinceAlbert]: Province.Saskatchewan,
  [ListedCity.MooseJaw]: Province.Saskatchewan,
};

export const cityToAbbreviatedProvinceMap: Record<string, string> = {
  Edmonton: 'AB',
  'Spruce Grove': 'AB',
  'St. Albert': 'AB',
  'Stony Plain': 'AB',
  'Sherwood Park': 'AB',
  'Red Deer': 'AB',
  Lethbridge: 'AB',
  'Grande Prairie': 'AB',
  Airdrie: 'AB',
  Beaumont: 'AB',
  Leduc: 'AB',
  'North Bay': 'ON',
  'Sault Ste. Marie': 'ON',
  Sudbury: 'ON',
  'Thunder Bay': 'ON',
  Saskatoon: 'SK',
  Martensville: 'SK',
  Warman: 'SK',
  Regina: 'SK',
  'Prince Albert': 'SK',
  'Moose Jaw': 'SK',
};
