import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import { Pagination } from '../pagination';
import { LISTINGS_PER_PAGE } from '../../const';

export interface MobileToggleControlsProps {
  toggleFilters: () => void;
  toggleList: () => void;
  showList: boolean;
  className?: string;
}
export const MobileToggleControls = ({
  toggleFilters,
  toggleList,
  showList,
  className,
  totalListings,
  listingsStats,
  listingsData,
  filterValues,
}: MobileToggleControlsProps) => {
  return (
    <div className="">
      <div
        className={twMerge(
          `flex gap-1 pl-4 pr-2 absolute -top-1 md:hidden right-0 w-full justify-between flex-col ${
            !showList ? 'bg-gray-100' : ''
          } z-10 py-3 md:py-0 md:mt-3`,
          className,
        )}
      >
        <div className="flex">
          <div className="h-2 flex-1 -z-10"></div>
          <button
            className="mx-0 bg-white active:bg-white shadow-md-dark text-coral-med focus:outline-none border-transparent w-52 rounded-full flex-1 px-12 md:px-0"
            onClick={() => toggleFilters()}
          >
            <span className="font-bold text-base">Filters</span>
          </button>
          <div className="flex-1 flex justify-end">
            <div
              className="overflow-hidden shadow-md-dark rounded-full flex cursor-pointer font-bold text-base z-10"
              onClick={() => toggleList()}
            >
              <div
                className={`flex-1 px-4 py-2 bg-white active:bg-white shadow-md-dark text-coral-med focus:outline-none border-transparent`}
              >
                {showList ? 'List' : 'Map'}
              </div>
            </div>
          </div>
        </div>

        {totalListings > LISTINGS_PER_PAGE && !showList && (
          <Pagination
            listingsStats={listingsStats}
            listingsData={listingsData}
            filterValues={filterValues}
          />
        )}
      </div>
    </div>
  );
};
