/* eslint-disable @next/next/no-img-element */
import NextLink from 'next/link';
import { useState } from 'react';
import { Icon, trackEvent } from '@requity-homes/component-library';
import { getCookie } from 'cookies-next';
import { getCustomerPortalBaseUrl } from '../../utils';
import { cookiesIdToken } from '@requity-homes/utils';

const listingsHomePage =
  process.env.NEXT_PUBLIC_STAGE === 'local-dev'
    ? '/'
    : 'https://requityhomes.com/listings';

const menuItems = (
  <>
    <a
      target="_blank"
      rel="noreferrer"
      href="https://www.requityhomes.com/how-it-works"
      className="font-bold hover:text-coral-medium"
    >
      How It Works
    </a>
    <a
      href={`${listingsHomePage}`}
      className="font-bold hover:text-coral-medium"
    >
      Browse Homes
    </a>
    <a
      target="_blank"
      rel="noreferrer"
      href="https://www.requityhomes.com/calculators"
      className="font-bold hover:text-coral-medium"
    >
      Calculators
    </a>
    <a
      target="_blank"
      rel="noreferrer"
      href="https://www.requityhomes.com/success-stories"
      className="font-bold hover:text-coral-medium"
    >
      Success Stories
    </a>
    <a
      target="_blank"
      rel="noreferrer"
      href="https://www.requityhomes.com/partners"
      className="font-bold hover:text-coral-medium"
    >
      Partners
    </a>
    <a
      target="_blank"
      rel="noreferrer"
      href="https://www.requityhomes.com/faq"
      className="font-bold hover:text-coral-medium"
    >
      FAQ
    </a>
  </>
);

const customerPortalBaseUrl = getCustomerPortalBaseUrl(
  process.env.NEXT_PUBLIC_STAGE,
);

export const Header = ({ city }: { city?: string }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const isLoggedIn = getCookie(cookiesIdToken);

  const handleOpenNewTab = (event) => {
    event.preventDefault();
    trackEvent('listing-sign-up-click', { city });
    window.open(`${customerPortalBaseUrl}/auth/sign-up`, '_blank');
  };

  const navigateToMyListings = (event) => {
    event.preventDefault();

    const myListingsPage = `${customerPortalBaseUrl}/v2/home-listings`;

    window.open(myListingsPage, '_blank');
  };

  return (
    <div className="bg-coral-light md:py-8 px-4 md:p-8 md:w-full z-30 h-24 flex items-center justify-between">
      <div className="flex gap-4 justify-between w-full">
        <div className="flex items-center justify-center">
          <a
            href="https://www.requityhomes.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://assets-global.website-files.com/61e19be21795b3fa062d021f/624dd69a94af1b5f89ef13f5_requity-logo.svg"
              alt="Requity Homes logo"
              className="w-28 md:w-36"
            />
          </a>
        </div>
        {showMobileMenu ? (
          <MobileMenu />
        ) : (
          <div className="hidden md:flex gap-12 items-center justify-center">
            {menuItems}
          </div>
        )}
        <div className="flex items-center justify-center gap-2 md:gap-4">
          {!isLoggedIn && (
            <NextLink passHref href="https://www.requityhomes.com/log-in">
              <a
                target="_blank"
                rel="noreferrer"
                className="text-coral-medium hover:text-white whitespace-nowrap hidden md:block"
              >
                <strong>Log In</strong>
              </a>
            </NextLink>
          )}
          {isLoggedIn ? (
            <div>
              <a
                className="py-2.5 md:py-3 px-4 md:px-8 rounded-3xl cursor-pointer text-white hover:text-coral-medium bg-coral-medium hover:bg-white hover:shadow-md-dark md:flex md:text-center font-bold whitespace-nowrap"
                onClick={(event) => navigateToMyListings(event)}
              >
                My Account
              </a>
              <button
                className="md:hidden h-7 w-7 ml-2"
                onClick={() => setShowMobileMenu(!showMobileMenu)}
              >
                {showMobileMenu ? (
                  <Icon glyph="close" className="md:hidden h-full w-full" />
                ) : (
                  <Icon
                    glyph="hamburgerMenu"
                    className="md:hidden h-full w-full"
                  />
                )}
              </button>
            </div>
          ) : (
            <div>
              <a
                className="py-2.5 md:py-3 px-4 md:px-8 rounded-3xl cursor-pointer text-white hover:text-coral-medium bg-coral-medium hover:bg-white hover:shadow-md-dark md:flex md:text-center font-bold whitespace-nowrap"
                onClick={(event) => handleOpenNewTab(event)}
              >
                Sign Up
              </a>
              <button
                className="md:hidden h-7 w-7 ml-2"
                onClick={() => setShowMobileMenu(!showMobileMenu)}
              >
                {showMobileMenu ? (
                  <Icon glyph="close" className="md:hidden h-full w-full" />
                ) : (
                  <Icon
                    glyph="hamburgerMenu"
                    className="md:hidden h-full w-full"
                  />
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const MobileMenu = () => {
  return (
    <div className="bg-coral-light border b-t pl-left-55 pt-4 flex flex-col gap-8 md:hidden absolute top-20 bottom-0 left-0 right-0 z-50 h-5000">
      {menuItems}
    </div>
  );
};
