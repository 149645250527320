import { Icon } from '@requity-homes/component-library';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

export const Pagination = ({ listingsStats, listingsData, filterValues }) => {
  const router = useRouter();

  const goToNextPage = useCallback(async () => {
    const pageNum = filterValues.page || 1;
    const queryParams = new URLSearchParams({
      province: router.query.province,
      ...filterValues,
      page: `${Number(pageNum) + 1}`,
    } as unknown as Record<string, string>);

    await router.push(`?${queryParams.toString()}`);
  }, [filterValues, router]);

  const goToPrevPage = useCallback(async () => {
    const queryParams = new URLSearchParams({
      province: router.query.province,
      ...filterValues,
      page: `${Number(filterValues.page) - 1}`,
    } as unknown as Record<string, string>);

    await router.push(`?${queryParams.toString()}`);
  }, [filterValues, router]);

  const isFirstPage = listingsStats?.page === 1;
  const isLastPage = listingsStats?.numPages === listingsStats?.page;

  return (
    <div className="flex gap-4 md:mb-0">
      <span
        className={`${isFirstPage ? 'opacity-50' : 'cursor-pointer'}`}
        onClick={() => (isFirstPage ? null : goToPrevPage())}
      >
        <Icon glyph="chevronLeft" />
      </span>
      <h3>
        {`Page ${listingsStats?.page} of ${listingsStats?.numPages} (${listingsData?.listings?.length} listings)`}
      </h3>

      <span
        className={`${isLastPage ? 'opacity-50' : ' cursor-pointer'}`}
        onClick={() => (isLastPage ? null : goToNextPage())}
      >
        <Icon glyph="chevronRight" />
      </span>
    </div>
  );
};
