import { generateListingUrl } from '@requity-homes/utils';
import { Listing } from '../graphql/generated';

const repliersCdnUrl = process.env.NEXT_PUBLIC_REPLIER_CDN_URL;
const stage = process.env.NEXT_PUBLIC_STAGE;

export const generateStructuredData = (listings: Listing[]) => {
  const structuredDataList = listings.map((listing) => {
    const listingUrl = generateListingUrl(listing, stage);

    const imageUrl = listing.images?.map(
      (url: string) => `${repliersCdnUrl}/${url}?className=medium`,
    )[0];

    return {
      '@context': 'https://schema.org',
      '@type': 'SingleFamilyResidence',
      identifier: listing.mlsNumber,
      description: listing.details.description,
      url: listingUrl,
      yearBuilt: listing.details.yearBuilt,
      address: {
        '@type': 'PostalAddress',
        streetAddress: listing.address.streetName,
        addressLocality: listing.address.city,
        addressRegion: listing.address.state,
        postalCode: listing.address.zip,
        addressCountry: 'CA',
      },
      image: imageUrl,
      numberOfRooms: listing.details.numRooms,
      numberOfBathroomsTotal: listing.details.numBathrooms,
      numberOfBedrooms: listing.details.numBedrooms,
      floorSize: {
        '@type': 'QuantitativeValue',
        value: listing.details.sqft,
        unitCode: 'sqft',
      },
      geo: {
        '@type': 'GeoCoordinates',
        latitude: listing.map.latitude,
        longitude: listing.map.longitude,
      },
      latitude: listing.map.latitude,
      longitude: listing.map.longitude,
    };
  });
  return structuredDataList;
};
