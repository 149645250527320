import Head from 'next/head';
import { ReactNode } from 'react';

const breadcrumbList = {
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      position: 1,
      name: 'How it works',
      item: 'https://www.requityhomes.com/how-it-works',
    },
    {
      '@type': 'ListItem',
      position: 2,
      name: 'Browse Homes',
      item: 'https://requityhomes.com/listings',
    },
    {
      '@type': 'ListItem',
      position: 3,
      name: 'Calculators',
      item: 'https://www.requityhomes.com/calculators',
    },
    {
      '@type': 'ListItem',
      position: 4,
      name: 'Success Stories',
      item: 'https://www.requityhomes.com/success-stories',
    },
    {
      '@type': 'ListItem',
      position: 5,
      name: 'Partners',
      item: 'https://www.requityhomes.com/partners',
    },
    {
      '@type': 'ListItem',
      position: 6,
      name: 'FAQ',
      item: 'https://www.requityhomes.com/faq',
    },
  ],
};

interface StructuredDataProps {
  '@context': string;
  '@type': string;
  identifier: string;
  description: string;
  url: string;
  yearBuilt: string;
  address: {
    '@type': string;
    streetAddress: string;
    addressLocality: string;
    addressRegion: string;
    postalCode: string;
    addressCountry: string;
  };
  image: string;
  numberOfRooms: string;
  numberOfBathroomsTotal: string;
  numberOfBedrooms: string;
  floorSize: {
    '@type': string;
    value: string;
    unitCode: string;
  };
  geo: {
    '@type': string;
    latitude: string;
    longitude: string;
  };
  latitude: string;
  longitude: string;
}

export interface MetaProps {
  title: string;
  description: string;
  keywords: string;
  ogImage: string;
  ogUrl: string;
  imageHeight?: string;
  imageWidth?: string;
  children?: ReactNode;
  structuredData?: StructuredDataProps[];
  disableBreadcrumbs?: boolean;
}

export const Meta = ({
  children,
  title,
  description,
  keywords,
  ogImage,
  ogUrl,
  imageHeight = '315',
  imageWidth = '315',
  structuredData,
  disableBreadcrumbs = false,
}: MetaProps) => (
  <Head>
    <title>{title}</title>
    <meta name="description" content={description} />
    <link rel="canonical" href={ogUrl} />

    {/* Breadcrumbs */}
    {!disableBreadcrumbs && (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbList) }}
      />
    )}

    {/* Structured data */}
    {structuredData?.map((data) => (
      <script
        key={data.url}
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
      />
    ))}

    {/* Open Graph metadata */}
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:url" content={ogUrl} />
    <meta property="og:type" content="website" />
    <meta property="og:image" content={ogImage} />
    <meta property="og:image:width" content={imageWidth} />
    <meta property="og:image:height" content={imageHeight} />

    {/* Twitter Card metadata */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:url" content={ogUrl} />
    <meta name="twitter:image" content={ogImage} />
    <meta property="og:image:width" content={imageWidth} />
    <meta property="og:image:height" content={imageHeight} />
    {children}
  </Head>
);
