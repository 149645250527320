import { defaultDownPaymentAmount } from '../const';

export const getInitialDownPayment = (homePrice, percentInDecimal) => {
  const initialDownPaymentAmount = homePrice * percentInDecimal;

  const computedInitialPayment =
    initialDownPaymentAmount < defaultDownPaymentAmount
      ? defaultDownPaymentAmount
      : initialDownPaymentAmount;
  return computedInitialPayment;
};
