export const defaultCity = 'Edmonton';
export const defaultSearchParams = {
  minPrice: 150000,
  maxPrice: 600000,
};
export const defaultCoordinates = {
  lat: -87.65,
  lng: 41.84,
};

export const defaultDownPaymentAmount = 5000;

export const defaultHomePrice = 250000;

export const defaultRentPercent = 0.02;

export const fivePercentSavingsTarget = 5;

export const TWO_PERCENT_DECIMAL = 0.02;

export const FIVE_PERCENT_DECIMAL = 0.05;

export const MINIMUM_HOME_PRICE = 150000;

export const MAXIMUM_HOME_PRICE = 600000;

export const LISTINGS_PER_PAGE = 200;
