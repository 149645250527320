export const queryParamsToCityMap: Record<string, string> = {
  edmonton: 'Edmonton',
  'spruce-grove': 'Spruce Grove',
  'st.-albert': 'St. Albert',
  'stony-plain': 'Stony Plain',
  'sherwood-park': 'Sherwood Park',
  'red-deer': 'Red Deer',
  lethbridge: 'Lethbridge',
  'grande-prairie': 'Grande Prairie',
  airdrie: 'Airdrie',
  beaumont: 'Beaumont',
  leduc: 'Leduc',
  'north-bay': 'North Bay',
  'sault-ste.-marie': 'Sault Ste. Marie',
  sudbury: 'Sudbury',
  'thunder-bay': 'Thunder Bay',
  saskatoon: 'Saskatoon',
  martensville: 'Martensville',
  warman: 'Warman',
  regina: 'Regina',
  'prince-albert': 'Prince Albert',
  'moose-jaw': 'Moose Jaw',
};
