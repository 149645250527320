import { SearchListingsFilterValues } from '../graphql/generated';
import { pick } from 'lodash';

export function filterOutNotSearchableValues(
  values: SearchListingsFilterValues,
) {
  return pick<SearchListingsFilterValues>(values, [
    'city',
    'minPrice',
    'maxPrice',
    'minBeds',
    'maxBeds',
    'sqft',
    'minBaths',
    'maxBaths',
    'yearBuilt',
    'hasImages',
    'boundary',
    'sortBy',
    'page',
  ]) as SearchListingsFilterValues;
}
