export enum Cities {
  Edmonton = 'edmonton',
  SpruceGrove = 'spruce-grove',
  StAlbert = 'st.-albert',
  StonyPlain = 'stony-plain',
  SherwoodPark = 'sherwood-park',
  RedDeer = 'red-deer',
  Lethbridge = 'lethbridge',
  GrandePrairie = 'grande-prairie',
  Airdrie = 'airdrie',
  Beaumont = 'beaumont',
  Leduc = 'leduc',
  NorthBay = 'north-bay',
  SaultSteMarie = 'sault-ste.-marie',
  Sudbury = 'sudbury',
  ThunderBay = 'thunder-bay',
  Saskatoon = 'saskatoon',
  Martensville = 'martensville',
  Warman = 'warman',
  Regina = 'regina',
  PrinceAlbert = 'prince-albert',
  MooseJaw = 'moose-jaw',
}
