import { Cities } from '../const';

export const getCityLink = (city) => {
  switch (city) {
    case Cities.Edmonton:
    case Cities.SpruceGrove:
    case Cities.StonyPlain:
    case Cities.SherwoodPark:
    case Cities.RedDeer:
    case Cities.Lethbridge:
    case Cities.Airdrie:
    case Cities.Beaumont:
    case Cities.Leduc:
    case Cities.GrandePrairie:
    case Cities.StAlbert:
      return `/ab/${city}/rent-to-own`;
    case Cities.NorthBay:
    case Cities.SaultSteMarie:
    case Cities.Sudbury:
    case Cities.ThunderBay:
      return `/on/${city}/rent-to-own`;
    case Cities.Saskatoon:
    case Cities.Martensville:
    case Cities.Warman:
    case Cities.Regina:
    case Cities.PrinceAlbert:
    case Cities.MooseJaw:
      return `/sk/${city}/rent-to-own`;
    default:
      return '';
  }
};
