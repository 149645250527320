import { cloneDeep } from 'lodash';
import { convertStringToFloat, getBoundPolygon } from '@requity-homes/utils';
import { queryParamsToCityMap } from './map-query-params-to-city';

export function prepareSearchFilterValues(values) {
  const clonedValues = cloneDeep(values);

  clonedValues.minPrice = convertStringToFloat(values.minPrice) || undefined;
  clonedValues.maxPrice = convertStringToFloat(values.maxPrice) || undefined;
  clonedValues.yearBuilt = convertStringToFloat(values.yearBuilt) || undefined;
  clonedValues.city = queryParamsToCityMap[clonedValues.city];

  if (values.boundary) {
    const boundary = values.boundary.split(',');

    const boundPolygon = getBoundPolygon(
      {
        lng: parseFloat(boundary[0]),
        lat: parseFloat(boundary[1]),
      },
      {
        lng: parseFloat(boundary[2]),
        lat: parseFloat(boundary[3]),
      },
    );
    clonedValues.boundary = JSON.stringify(boundPolygon.coordinates);
  }
  return clonedValues;
}
